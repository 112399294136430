import { onMounted, ref, watch } from 'vue'
// import { useToast } from 'vue-toastification'
import { getCountries, createCountry, removeCountry } from '@/services/countries.service'

export function useCountries () {
  // const toast = useToast()
  const countries = ref([])
  const currentPage = ref(1)
  const searchQuery = ref(undefined)
  const isLoading = ref(false)

  const tableHeaders = [
    'Изображения',
    'Название (RU | ENG | UZ)',
    'Стоимость доставки',
    'Действия'
  ]

  watch([searchQuery, currentPage], () => {
    fetchCountries()
  })

  const onPageChange = (page) => {
    currentPage.value = page
  }

  const search = (e) => {
    // eslint-disable-next-line no-unused-vars
    const [input, ...rest] = e.target
    searchQuery.value = input.value
  }

  const fetchCountries = async () => {
    const params = {
      title: searchQuery.value,
      paginate: 10,
      page: currentPage.value
    }
    const countriesResponse = await getCountries(params)
    countries.value = countriesResponse.data
  }

  const addCountry = async (formValues) => {
    const formData = new FormData()

    Object.entries(formValues).forEach(([name, value]) => {
      formData.append(name, value)
    })

    await createCountry(formData)
    window.location.reload()
  }

  const deleteCountry = async (id) => {
    if (confirm('delete ?')) {
      await removeCountry(id)
      await fetchCountries()
    }
  }

  onMounted(async () => {
    await fetchCountries()
  })

  return {
    countries,
    tableHeaders,
    searchQuery,
    currentPage,
    isLoading,
    onPageChange,
    search,
    addCountry,
    deleteCountry
  }
}
