<template>
  <div>
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-md-4 d-flex justify-content-between align-items-center">
                <div class="header-title me-5">
                  <h4 class="card-title">Страны</h4>
                </div>
                <button
                  type="button"
                  class="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#add-countries"
                >
                  Добавить
                </button>
              </div>
              <form @submit.prevent="search" class="col-md-4 offset-md-4 d-flex align-items-center">
                <div class="form-group input-group mb-0">
                  <input type="search" class="form-control" placeholder="Поиск">
                  <button class="input-group-text" id="basic-addon1" type="submit">
                    <svg width="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="11.7669" cy="11.7666" r="8.98856" stroke="currentColor" stroke-width="1.5"
                                stroke-linecap="round" stroke-linejoin="round"></circle>
                        <path d="M18.0186 18.4851L21.5426 22" stroke="currentColor" stroke-width="1.5"
                              stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div class="card-body px-0">
            <div class="table-responsive">
              <table id="user-list-table" class="table" role="grid" data-toggle="data-table">
                <thead>
                <tr class="ligth">
                  <th v-for="(header, index) in tableHeaders"
                      :key="index"
                      :class="{ 'text-end': tableHeaders.length - 1 === index }"
                  >
                    {{ header }}
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(country, index) in countries.data" :key="index">
                  <td>
                    <a :href="country.image" target="_blank">
                      <img :src="country.image" :alt="country.id" width="120">
                    </a>
                  </td>
                  <td>
                    {{ country.name.ru }} |
                    {{ country.name.eng }} |
                    {{ country.name.uz }}
                  </td>
                  <td>{{ country.price }}</td>
                  <td class="text-end">
                    <div class="dropdown">
                      <a class="btn btn-primary dropdown-toggle"
                         href="#"
                         role="button"
                         id="dropdownMenuLink"
                         data-bs-toggle="dropdown"
                         aria-expanded="false"
                      >
                        Опции
                      </a>
                      <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                        <li>
                          <router-link class="dropdown-item" :to="{ name: 'default.countries.edit', params: { id: country.id } }">
                            Редактировать
                          </router-link>
                        </li>
                        <li>
                          <a class="dropdown-item text-danger" href="#" @click="deleteCountry(country.id)">&times; Удалить</a>
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div v-if="countries?.pagination?.total > 10" class="card-footer d-flex justify-content-center">
            <div class="bd-content">
              <CustomPagination
                :total-pages="countries?.pagination?.total_pages || 1"
                :total="countries?.pagination?.total || 1"
                :per-page="10"
                :current-page="currentPage"
                @pagechanged="onPageChange"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <modal style="display: none" mainClass="fade" :tabindex="-1" id="add-countries"
           ariaLabelled="addModalLabel" :ariaHidden="true"
           dialog-class="modal-lg modal-dialog-scrollable"
    >
      <model-header :dismissable="true"></model-header>

      <model-body class="px-5">
        <h1 class="text-center">Добавление страны</h1>

        <Form class="row mt-4" @submit="addCountry" v-slot="{ meta }">
          <div class="col-md-4">
            <label for="name" class="form-label">Название (English)</label>
            <Field
              id="name"
              label="Название (English)"
              name="names[eng]"
              type="text"
              class="form-control"
              rules="required"
            />
            <ErrorMessage name="names[eng]" class="text-danger" />
          </div>

          <div class="col-md-4">
            <label for="name" class="form-label">Название (O’zbek)</label>
            <Field
              id="name"
              type="text"
              class="form-control"
              name="names[uz]"
              rules="required"
            />
            <ErrorMessage name="names[uz]" class="text-danger" />
          </div>

          <div class="col-md-4">
            <label for="name" class="form-label">Название (Русский)</label>
            <Field
              id="name"
              type="text"
              class="form-control"
              name="names[ru]"
              rules="required"
            />
            <ErrorMessage class="text-danger" name="names[ru]" />
          </div>

          <div class="mt-4"></div>

          <div class="col-md-4">
            <label for="phone" class="form-label">Номер контактного лица</label>
            <Field
              id="phone"
              type="tel"
              class="form-control"
              rules="required"
              name="phone"
            />
            <ErrorMessage class="text-danger" name="phone" />
          </div>

          <div class="col-md-4">
            <label for="country-photo" class="form-label">Фото страны</label>
            <Field
              id="country-photo"
              type="file"
              class="form-control"
              rules="required"
              name="image"
            />
            <ErrorMessage class="text-danger" name="image" />
          </div>

          <div class="col-md-4">
            <label for="cost" class="form-label">Стоимость доставки</label>
            <Field
              id="cost"
              type="number"
              class="form-control"
              rules="required"
              name="price"
            />
            <ErrorMessage class="text-danger" name="price" />
          </div>

          <div class="mt-4"></div>

          <div class="text-center col-12">
            <button :disabled="!(meta.valid && meta.dirty)" type="submit" class="btn btn-primary">Сохранить</button>
          </div>
        </Form>

        <br><br>
      </model-body>
    </modal>
  </div>
</template>

<script>
import CustomPagination from '../../../components/custom/pagination/CustomPagination'
import modal from '../../../components/bootstrap/modal/modal'
import modelHeader from '../../../components/bootstrap/modal/model-header'
import modelBody from '../../../components/bootstrap/modal/model-body'
import { useCountries } from './useCountries'
import { Form, Field, ErrorMessage } from 'vee-validate'

export default {
  name: 'CountriesIndex',
  setup () {
    const {
      countries,
      tableHeaders,
      currentPage,
      searchQuery,
      isLoading,
      onPageChange,
      search,
      addCountry,
      deleteCountry
    } = useCountries()

    return {
      tableHeaders,
      countries,
      currentPage,
      searchQuery,
      isLoading,
      onPageChange,
      search,
      addCountry,
      deleteCountry
    }
  },
  components: {
    CustomPagination,
    modal,
    modelBody,
    modelHeader,
    Form,
    Field,
    ErrorMessage
  }
}
</script>
